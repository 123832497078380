import simg1 from '../images/service/1.jpg'
import simg2 from '../images/service/2.jpg'
import simg3 from '../images/service/3.jpg'
import simg4 from '../images/service/4.jpg'
import simg5 from '../images/service/5.jpg'
import simg6 from '../images/service/6.jpg'

import sSimg1 from '../images/service-single/img-1.jpg'
import sSimg2 from '../images/service-single/img-2.jpg'
import sSimg3 from '../images/service-single/img-3.jpg'
import sSimg4 from '../images/service-single/img-4.jpg'
import sSimg5 from '../images/service-single/img-5.jpg'
import sSimg6 from '../images/service-single/img-6.jpg'

import icon1 from '../images/service/1.svg'
import icon2 from '../images/service/2.svg'
import icon3 from '../images/service/3.svg'
import icon4 from '../images/service/4.svg'
import icon5 from '../images/service/5.svg'
import icon6 from '../images/service/6.svg'


const Services = [
   {
      Id: '01',
      sImg: simg1,
      sSImg: sSimg1,
      icon:icon1,
      title: 'Quran Memorization',
      slug: 'Quran-Memorization',
      description: 'Betus acipsum srci fusid commod atutro. Enim facilisis donec gomdo enyn. Digsim amet feugiat nec proin lacinia.',
   },
   {
      Id: '02',
      sImg: simg2,
      sSImg: sSimg2,
      icon:icon2,
      title: 'Special Child Care',
      slug: 'Special-Child-Care',
      description: 'Betus acipsum srci fusid commod atutro. Enim facilisis donec gomdo enyn. Digsim amet feugiat nec proin lacinia.',
   },
   {
      Id: '03',
      sImg: simg3,
      sSImg: sSimg3,
      icon:icon3,
      title: 'Mosque Development',
      slug: 'Mosque-Development',
      description: 'Betus acipsum srci fusid commod atutro. Enim facilisis donec gomdo enyn. Digsim amet feugiat nec proin lacinia.',
   },
   {
      Id: '04',
      sImg: simg4,
      sSImg: sSimg4,
      icon:icon4,
      title: 'Charity & Donation',
      slug: 'Charity-&-Donation',
      description: 'Betus acipsum srci fusid commod atutro. Enim facilisis donec gomdo enyn. Digsim amet feugiat nec proin lacinia.',
   },
   {
      Id: '05',
      sImg: simg5,
      sSImg: sSimg5,
      icon:icon5,
      title: 'Matrimonial',
      slug: 'Matrimonial',
      description: 'Betus acipsum srci fusid commod atutro. Enim facilisis donec gomdo enyn. Digsim amet feugiat nec proin lacinia.',
   },
   {
      Id: '06',
      sImg: simg6,
      sSImg: sSimg6,
      icon:icon6,
      title: 'Funerals',
      slug: 'Funerals',
      description: 'Betus acipsum srci fusid commod atutro. Enim facilisis donec gomdo enyn. Digsim amet feugiat nec proin lacinia.',
   }

]

export default Services;