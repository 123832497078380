import React from "react";
import { Link } from 'react-router-dom'
import hero1 from '../../images/slider/img.jpg'
import icon from '../../images/slider/icon.svg'


const Hero = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="static-hero">
            <div className="container-fluid">
                <div className="content">
                    <div className="icon">
                        <img src={icon} alt="icon"/>
                    </div>
                    <h2 className="title">“turn to Allah before
                        you return to Allah.”</h2>
                    <span className="subtitle">The heart that beats for Allah is always a stranger among the hearts
                        that beat for the Dunya (world).</span>
                    <div className="hero-btn">
                        <Link onClick={ClickHandler} to="/about" className="theme-btn">Discover More</Link>
                    </div>
                </div>
                <div className="right-img">
                    <img src={hero1} alt=""/>
                </div>
            </div>
        </section>
    )
}

export default Hero;