import React from "react";


const PrayerTime = (props) => {
    return (
        <section className={`prayertime-section ${props.prClass}`}>
            <div className="container">
                <div className="prayertime-wrap">
                    <div className="row g-0" id="prayerTimeRow">
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="item">
                                <h2>Fajar</h2>
                                <span>4:10 am</span>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="item">
                                <h2>Sunrize</h2>
                                <span>5:50 am</span>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="item">
                                <h2>Zuhr</h2>
                                <span>12:36 pm</span>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="item">
                                <h2>Asr</h2>
                                <span>4:15 pm</span>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="item">
                                <h2>Maghrib</h2>
                                <span>6:15 pm</span>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="item">
                                <h2>Isha'a</h2>
                                <span>8:30 pm</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PrayerTime;